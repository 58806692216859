.app {
  text-align: center;
  padding-top: 20px;
  background-color: #fcfaf9;
  color: #333333;
  min-height: 100vh;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.thoughtInputContainer {
  width: 60%;
}

.thoughtTextArea {
  width: 100%;
  height: 200px;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  font-family: sans-serif;
}

button {
  padding: 15px 30px;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
  background-color: #f3d3bd;
}

.thoughtText {
  font-size: 24px;
  font-family: sans-serif;
  transform-origin: center;
  position: absolute;
  z-index: 10;
  white-space: pre-wrap; /* Preserve line breaks */
  text-align: center;
  max-width: 80%;
}

/* Existing styles */
.animatedChar {
  font-family: sans-serif;
  display: inline-block;
  width: auto;
  height: auto;
}

.animationGif {
  width: 150px; /* Set the desired width */
  height: 150px; /* Set the desired height */
  object-fit: cover; /* Adjust how the GIF fits within the specified dimensions */
}
